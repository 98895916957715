import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import { isIframe } from '@/lib/utils';
import {
  useAppStore,
  useAssessmentStore,
  useEditorStore,
  useModalStore,
  useUserStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  data() {
    return {
      markingAssignmentComplete: false,
    };
  },
  computed: {
    ...mapState(useAppStore, [
      'apiToken',
    ]),
    ...mapState(useAssessmentStore, [
      'attempt',
      'attemptIsSubmitted',
      'userCanEditAttemptQuizItems',
      'pageIsFullSlideTEI',
      'hasPageTeis',
    ]),
    ...mapState(useEditorStore, [
      'draftAssetId',
      'draftHasInteractiveModules',
      'domains',
      'draft',
    ]),
    ...mapState(useUserStore, [
      'userCanAssign',
      'userDataIsLoaded',
      'userIsStudent',
    ]),
    showAssignButton() {
      return this.userDataIsLoaded
      && this.userCanAssign
      && !this.isGrading
      && !isIframe()
      && this.mode === 'VIEW';
    },
    showSubmitButtons() {
      return this.userIsStudent
        && this.homeworkId
        && this.draft?.pages
        && this.mode === 'VIEW';
    },
    showSubmitAssignment() {
      return this.showSubmitButtons
        && this.draftHasInteractiveModules
        && this.userCanEditAttemptQuizItems;
    },
    showViewResults() {
      return this.showSubmitButtons
        && !this.isGrading
        && this.attemptIsSubmitted
        && (this.draftHasInteractiveModules || this.hasPageTeis);
    },
    showMarkAsComplete() {
      return this.showSubmitButtons && !this.draftHasInteractiveModules
        && !this.hasPageTeis && !this.markedAsComplete;
    },
    showCompleted() {
      return this.showSubmitButtons && !this.draftHasInteractiveModules
        && !this.hasPageTeis && this.markedAsComplete;
    },
    homeworkId() {
      return this.$route?.query?.homework_id;
    },
    isGrading() {
      const { source } = get(this, '$route.query', {});
      // account for 'old' source qsp that may be present with the new source urls
      if (Array.isArray(source)) {
        return source.includes('grade_assignment');
      }
      return source === 'grade_assignment';
    },
    markedAsComplete() {
      return !!this.draft?.assignmentIsComplete;
    },
    viewResultsUrl() {
      return `${this.domains.appDomain}/assessment/results/${this.attempt?.id}`;
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.SET_DRAFT_IS_DIRTY,
      types.PUBLISH_DRAFT,
      types.MARK_AS_COMPLETE,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    openSubmitAssignmentModal() {
      this[types.OPEN_MODAL]({
        type: 'SubmitAssignmentModal',
      });
    },
    openConfirmAssignModal() {
      const getAssignUrl = (creation) => {
        const host = this.domains.appDomain;
        const assetId = this.draftAssetId(creation);

        const qsp = new URLSearchParams(window.location.search);
        qsp.set('disable_back_button', 1);

        const backUrl = encodeURIComponent(`${window.location.origin}${window.location.pathname}?${qsp.toString()}`);
        return `${host}/assign/${assetId}?back=${backUrl}`;
      };

      this[types.OPEN_MODAL]({
        type: 'ConfirmAssignModal',
        onContinue: async () => {
          if (this.mode === 'EDIT') {
            this[types.SET_DRAFT_IS_DIRTY](false);
            const creation = await this[types.PUBLISH_DRAFT]({ draftId: this.draft.id });
            window.location.href = getAssignUrl(creation);
          } else {
            window.location.href = getAssignUrl();
          }
        },
      });
    },
    async markAssignmentAsComplete() {
      if (!this.homeworkId) return;
      this.markingAssignmentComplete = true;
      await this[types.MARK_AS_COMPLETE]({ homeworkId: this.homeworkId });
      this.markingAssignmentComplete = false;
    },
  },
};
